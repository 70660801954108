<template>
    <v-card flat>
        <v-card-title>
            <div class="mb-4">
                <span class="secondary--text font-weight-bold">New {{ selectedSpaceInfo.type }}</span>
            </div>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <div>
                    <v-text-field
                        v-model="spaceName"
                        :label="`${selectedSpaceInfo.type} name*`"
                        :counter="50"
                        :rules="[rules.nonEmpty]"
                        required
                        :disabled="loading || fetchingSpaces"
                        autofocus
                        hide-details
                        outlined></v-text-field>
                    <ShortIdInput :disabledEdit="loading || fetchingSpaces" :longId="spaceName" @input="updateShortId($event.nextValue)"></ShortIdInput>
                </div>
                <v-textarea
                    class="mt-5"
                    v-model="spaceDescription"
                    label="Short description*"
                    rows="1"
                    auto-grow
                    :rules="[rules.nonEmpty]"
                    required
                    :disabled="loading || fetchingSpaces"
                    outlined></v-textarea>
                <v-row align="center" v-if="!sidToClone" class="mb-4">
                    <v-col cols="1">
                        <span class="subtitle-1">Visibility</span>
                    </v-col>
                    <v-col>
                        <div class="d-flex align-center">
                            <span class="ml-1 font-weight-medium">{{ selectedSpaceInformation.label }}</span>
                            <v-switch :disabled="loading || fetchingSpaces" v-model="showVisibilityOptions" hide-details class="my-0 ml-5" dense>
                                <template v-slot:label>
                                    <span class="caption">More options</span>
                                </template>
                            </v-switch>
                        </div>
                        <v-radio-group v-if="showVisibilityOptions" :ripple="false" v-model="spaceVisibility">
                            <div v-for="(visibilityType, index) in spaceVisibilityTypes" :key="index">
                                <v-radio class="mt-3 caption" color="primary" :value="visibilityType.label" :label="visibilityType.label"></v-radio>
                                <span :class="spaceVisibility === visibilityType.label ? 'caption secondary--text' : 'caption'">
                                    {{ visibilityType.description }}
                                </span>
                            </div>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <div v-if="this.newSpaceType === this.spaceTypes.EDUCATION_SPACE" class="d-flex flex-column">
                    <v-menu
                        v-model="spaceArchiveDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="spaceArchiveByDate"
                                readonly
                                label="Space archival date"
                                :rules="[rules.nonEmpty]"
                                required
                                v-bind="attrs"
                                v-on="on"
                                outlined></v-text-field>
                        </template>
                        <v-date-picker v-model="spaceArchiveByDate" @input="spaceArchiveDateMenu = false"></v-date-picker>
                    </v-menu>
                </div>
                <div class="d-flex align-center">
                    <v-switch
                        :disabled="loading || fetchingSpaces"
                        v-model="showCloneOptions"
                        class="my-0 ml-5"
                        :color="showCloneOptions ? 'success' : 'default'"
                        inset>
                        <span class="ml-1 font-weight-medium">Create space based on a template</span>

                        <template v-slot:label>
                            <span class="caption text-body-2">Create space based on a template</span>
                        </template>
                    </v-switch>
                </div>
                <v-radio-group row v-model="cloneRadio" id="cloneRadio">
                    <v-row v-if="showCloneOptions" style="justify-content: space-around">
                        <v-col>
                            <v-card class="ma-3" :elevation="cloneRadio == 'space' ? 8 : 1" :outlined="cloneRadio == 'space'">
                                <v-card-text>
                                    <v-radio value="space">
                                        <template v-slot:label>
                                            <v-autocomplete
                                                style="z-index: 2"
                                                clearable
                                                :items="spacesWithCurrentType"
                                                :disabled="cloneRadio == 'repository' || fetchingSpaces || loading"
                                                :loading="fetchingSpaces"
                                                v-model="sidToClone"
                                                item-text="space_long_id"
                                                item-value="sid"
                                                placeholder="Clone an existing space"
                                                outlined
                                                hide-details
                                                class="ml-2"></v-autocomplete>
                                        </template>
                                    </v-radio>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col>
                            <v-card class="ma-3" :elevation="cloneRadio == 'repository' ? 8 : 1" :outlined="cloneRadio == 'repository'">
                                <v-card-text>
                                    <v-radio value="repository">
                                        <template v-slot:label>
                                            <v-text-field
                                                v-model="githubURL"
                                                :label="sidToClone ? 'Github repositories are only available in empty spaces' : 'Clone a Github repository'"
                                                hide-details
                                                :disabled="cloneRadio == 'space'"
                                                :rules="[rules.isGithubURL]"
                                                outlined
                                                class="ml-2"></v-text-field>

                                            <span class="secondary--text caption ml-2" v-if="!githubURL">Use SSH (git@...) or HTTPS (http://...) URLs.</span>
                                            <span class="secondary--text caption ml-2" v-if="githubURL && !repoURLIsHTTPS">
                                                Set up your
                                                <a target="_blank" href="https://docs.nuvolos.cloud/faqs/faqs#is-git-supported">Nuvolos SSH keys</a>
                                                before cloning via SSH.
                                            </span>
                                            <span class="secondary--text caption" v-if="githubURL && repoURLIsHTTPS">
                                                You can only initialize from public repositories via HTTPS. If you plan to push commits to the remote repository
                                                from Nuvolos, use an SSH URL instead for better experience.
                                            </span>
                                        </template>
                                    </v-radio>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-radio-group>
                <v-row justify="center">
                    <v-col cols="12" xl="6" v-if="showCloneOptions">
                        <v-alert type="warning" dense colored-border border="left" elevation="2">
                            Copying content to the new space will execute as a background task and may take up to 10 minutes.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-checkbox :disabled="loading || fetchingSpaces || showCloneOptions" v-model="addReadme" v-if="!(sidToClone || githubURL)">
                    <template v-slot:label>
                        <span>Add README</span>
                    </template>
                </v-checkbox>
                <div v-if="addReadme && !(sidToClone || githubURL)">
                    <MarkdownEditor :placeholderText="selectedSpaceInfo.readmePlaceholder" @newMarkdownContent="readmeFileContent = $event.value" />
                </div>
            </v-form>
        </v-card-text>
        <v-alert max-width="600" :value="error" color="error" icon="warning" class="mt-4 mx-auto" text>
            <div class="d-flex flex-column">
                <span class="font-weight-bold">{{ errorContent }}</span>
                <span>
                    For more information on space creation issues, check the troubleshooting documentation:
                    <v-btn
                        class="font-weight-bold"
                        small
                        text
                        color="error"
                        href="https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-create-a-space"
                        target="_blank">
                        here
                    </v-btn>
                </span>
            </div>
        </v-alert>
        <v-card-actions>
            <div class="d-flex flex-column align-center w-100">
                <div class="d-flex align-center">
                    <v-btn
                        class="mr-1"
                        :loading="loading || fetchingSpaces"
                        :disabled="!valid || loading || fetchingSpaces"
                        @click="sidToClone ? cloneSpace() : addSpace()"
                        color="primary"
                        elevation="0">
                        <v-icon small>add</v-icon>
                        {{ sidToClone ? 'Clone' : 'Add' }} Space
                    </v-btn>
                    <v-btn
                        :to="{ name: 'home-dashboard', params: { oid: $route.params.oid } }"
                        color="primary"
                        outlined
                        text
                        exact
                        :disabled="loading || fetchingSpaces">
                        cancel
                    </v-btn>
                </div>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { get } from 'lodash'
const proposedArchivalDate = new Date()
proposedArchivalDate.setMonth(proposedArchivalDate.getMonth() + 8)
const MarkdownEditor = () => import('@/components/MarkdownEditor')
const ShortIdInput = () => import('@/components/ShortIdInput')

export default {
    mixins: [enumsData],
    data() {
        return {
            addReadme: true,
            spaceName: '',
            spaceShortName: '',
            spaceDescription: '',
            githubURL: '',
            spaceArchiveByDate: proposedArchivalDate.toISOString().split('T')[0],
            spaceArchiveDateMenu: false,
            showVisibilityOptions: false,
            showCloneOptions: false,
            cloneRadio: this.$route.query.sid ? 'space' : 'repository',
            sidToClone: parseInt(this.$route.query.sid) || null,
            spaceVisibility: 'Private',
            spaceVisibilityTypes: [
                {
                    label: 'Private',
                    code: 3,
                    description: 'Only you, the organization managers, and the people you explicitly invite can see the space.'
                },
                {
                    label: 'Faculty-only',
                    code: 2,
                    description: 'Only you, the organization managers, the people you explicitly invite, and faculty members can see the space.'
                },
                {
                    label: 'Public',
                    code: 0,
                    description: 'Every member of this organization can see the space.'
                }
            ],
            error: false,
            errorContent: 'AN ERROR HAS OCCURRED',
            rules: {
                nonEmpty: p => p.length > 0 || 'Please provide a value.',
                isGithubURL(url) {
                    if (!url) return true
                    const githubEpxression = /((git|ssh|http(s)?)|(git@[\w.]+))(:(\/\/)?)([\w.@:/\-~]+)(\.git)(\/)?/
                    return url.match(githubEpxression) !== null
                }
            },
            loading: false,
            valid: false,
            readmeFileContent: ''
        }
    },
    components: {
        MarkdownEditor,
        ShortIdInput
    },
    props: {
        spaceTypeIcon: String,
        newSpaceType: Number
    },
    methods: {
        updateShortId(newShortId) {
            this.spaceShortName = newShortId
        },
        addReadmeFile(snid) {
            const blob = new Blob([this.readmeFileContent])
            const formData = new FormData()
            formData.append('file', blob, 'README.md')
            formData.append('type', 'text/markdown')
            formData.append('name', 'README.md')
            const endpoint = `/snapshots/${snid}/fs/files`
            this.$axios
                .post(endpoint, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(() => {
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: snid, route: this.$route, setFetchingStatus: true })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Failed to create the README file.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                })
        },
        addSpace: function () {
            this.error = false
            this.noSpaceDescription = false
            const apiURL = `/orgs/${this.$route.params.oid}/spaces`
            this.loading = true
            const archDate = this.$props.newSpaceType === this.spaceTypes.EDUCATION_SPACE ? this.spaceArchiveByDate : null
            const postBody = {
                description: this.spaceDescription,
                long_id: this.spaceName,
                short_id: this.spaceShortName,
                space_type: this.$props.newSpaceType,
                visibility_type: this.selectedSpaceInformation.code,
                archive_by_date: archDate,
                rtypes: [10, 20, 30, 50, 90]
            }
            if (this.githubURL) postBody.github_url = this.githubURL
            this.$axios
                .post(apiURL, postBody, { timeout: 300000 })
                .then(response => {
                    const spaceData = {
                        oid: response.data.oid,
                        sid: response.data.sid
                    }
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Space created successfully',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid).finally(() => {
                        if (this.createSpaceAndApp.operationSelected) {
                            this.$axios.get(`spaces/${response.data.sid}/master/development`).then(response => {
                                const development = response.data
                                this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 2 })
                                this.$router.push({
                                    name: 'snapshot-applications-add',
                                    params: {
                                        oid: spaceData.oid,
                                        sid: spaceData.sid,
                                        iid: development.iid,
                                        snid: development.snid
                                    }
                                })
                                if (this.addReadme && !this.githubURL) {
                                    this.addReadmeFile(development.snid)
                                }
                            })
                        }
                    })
                })
                .catch(error => {
                    this.error = true
                    const errorMsg = get(error, 'response.data.reason[0]')
                    if (errorMsg && errorMsg.includes('UniqueViolation') && errorMsg.includes('spc_uix_long_id_oid')) {
                        this.errorContent = 'The name you chose for this space is used by another space, please choose another name.'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async cloneSpace() {
            this.error = false
            this.noSpaceDescription = false
            const apiURL = `/spaces/${this.sidToClone}/copy`
            this.loading = true
            const archDate = this.$props.newSpaceType === this.spaceTypes.EDUCATION_SPACE ? this.spaceArchiveByDate : null
            const postBody = {
                oid: this.$route.params.oid,
                description: this.spaceDescription,
                long_id: this.spaceName,
                short_id: this.spaceShortName,
                archive_by_date: archDate
            }
            try {
                const {
                    data: { oid, sid }
                } = await this.$axios.post(apiURL, postBody, { timeout: 300000 })

                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'The space is being prepared for you.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'check_circle'
                })
                await this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)

                if (this.createSpaceAndApp.operationSelected) {
                    const {
                        data: { iid, snid }
                    } = await this.$axios.get(`spaces/${sid}/master/development`)
                    this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { stepVal: 2 })
                    this.$router.push({
                        name: 'snapshot-applications-add',
                        params: {
                            oid,
                            sid,
                            iid,
                            snid
                        }
                    })
                }
            } catch (error) {
                this.error = true
                const errorMsg = get(error, 'response.data.reason[0]')
                if (errorMsg && errorMsg.includes('UniqueViolation') && errorMsg.includes('spc_uix_long_id_oid')) {
                    this.errorContent = 'The name you chose for this space is used by another space, please choose another name.'
                }
            }
            this.loading = false
        }
    },
    computed: {
        ...mapState('orgStore', ['fetchingSpaces', 'spacesWithPrimarySnapshots']),
        ...mapState('homeStore', ['createSpaceAndApp']),
        selectedSpaceInfo() {
            if (this.newSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return {
                    type: 'Course',
                    readmePlaceholder:
                        'You can add a README file to a course to communicate important information about the course. The README content can be updated anytime and will be automatically shared with students on each distribution.'
                }
            } else if (this.newSpaceType === this.spaceTypes.RESEARCH_SPACE) {
                return {
                    type: 'Research Project',
                    readmePlaceholder:
                        'You can add a README file to a research project to describe and communicate detailed information about the project. The README content can be updated anytime.'
                }
            } else {
                return {
                    type: 'Dataset',
                    readmePlaceholder:
                        'You can add a README file to a dataset to describe detailed information about the dataset. The README content can be updated anytime.'
                }
            }
        },
        selectedSpaceInformation() {
            return this.spaceVisibilityTypes.find(item => item.label === this.spaceVisibility)
        },
        repoURLIsHTTPS() {
            return this.githubURL.toLowerCase().startsWith('https')
        },
        spacesWithCurrentType() {
            return this.spacesWithPrimarySnapshots.filter(space => {
                return space.space_type === this.$props.newSpaceType && space.space_role === this.roleTypes.SPACE_ADMIN
            })
        }
    },
    watch: {
        showCloneOptions(on) {
            if (on) {
                this.addReadme = false
                return
            }
            this.sidToClone = null
            this.githubURL = null
        },
        cloneRadio(newVal) {
            if (newVal === 'space') {
                this.githubURL = null
            } else {
                this.sidToClone = null
            }
        }
    },
    mounted() {
        if (!this.createSpaceAndApp.operationSelected) {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', { operationSelectedVal: true, stepVal: 1 })
        }
        if (this.$route.query.sid) {
            this.showCloneOptions = true
        }
    },
    beforeDestroy() {
        if (this.createSpaceAndApp.operationSelected && this.createSpaceAndApp.step !== 2) {
            this.$store.dispatch('homeStore/resetCreateSpaceAndApp')
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep #cloneRadio {
    label.v-label {
        flex-direction: column;
        align-items: start;
    }
    div.v-input.v-text-field {
        width: 100%;
    }
    .v-card {
        border-color: var(--v-success-base);
    }
}
</style>
